<template>
  <div class="border-bottom">
    <header :class="{ 'navbar-alert' : store.altert, 'navbar-banner': store.banner.content }">
      <nav
        class="navbar navbar-default navbar-fixed-top navbar-front nav-project-sell nav-project-sell-general p-0"
        id="header"
      >
        <banners-module />
        <div class="content-element global-container">
          <div class="item-content">
            <div
              :class="
                !subdomain
                  ? ['menu-left d-flex justify-content-between align-center']
                  : [
                      'menu-left d-flex justify-content-between align-center subdomain',
                    ]
              "
            >
              <a class="logo" :title="title" :href="'/' + route">
                <img
                  :src="
                    getLogos(
                      subdomain ? `logo-${subdomain}` : 'carizy',
                      subdomain ? 'png' : 'svg',
                    )
                  "
                  class="FunnelHeader__logo d-sm-none d-none d-md-block"
                  height="22px"
                  alt="backToHome"
                />
              </a>
              <p
                class="d-none d-xl-block"
                @mouseover="activeSeller = true"
                @mouseleave="activeSeller = false"
              >
                <a
                  :href="validateUrl('/vendre-ma-voiture')"
                  title="vendre ma voiture"
                  @click="
                    eventTracking('vendre_voiture', 'clics_module', 'header')
                  "
                >
                  <span class="border">Vendre une voiture</span>
                </a>
                <sub-menu-seller v-show="activeSeller" />
              </p>
              <p
                class="d-none d-xl-block"
                @mouseover="activeBuyer = true"
                @mouseleave="activeBuyer = false"
              >
                <a
                  :href="validateUrl('/voiture-occasion')"
                  title="voiture occasion"
                  @click="
                    eventTracking('acheter_voiture', 'clics_module', 'header')
                  "
                >
                  <span class="border">Acheter une voiture</span>
                </a>
                <sub-menu-buyer v-show="activeBuyer" />
              </p>
              <div
                class="d-none d-xl-block"
                @mouseover="activeBoard = true"
                @mouseleave="activeBoard = false"
              >
                <p>
                  Nos services
                  <span class="border" />
                </p>
                <sub-menu-board v-show="activeBoard" />
              </div>
              <div
                class="d-none d-xl-block"
                @mouseover="activeAdvice = true"
                @mouseleave="activeAdvice = false"
              >
                <p>
                  <a
                      :href="validateUrl('/blog')"
                      title="Nos conseils"
                      @click="eventTracking('nos-conseils', 'clics_module', 'header')"
                  >
                    <span class="border">Nos conseils</span>
                  </a>
                </p>
                <sub-menu-advice
                  v-show="activeAdvice"
                  :top-articles="topArticles"
                />
              </div>
            </div>
            <div
              class="d-none d-xl-block d-flex justify-content-end align-center btn-container btn-flex"
            >
              <a
                id="click-to-call"
                :href="'tel:' + phoneCompactPrefix"
                class="btn-green-outline btn"
                :title="phoneNumber"
                @click="eventTracking('call', 'clics_module', 'header')"
              >
                <img
                    :src="getPicto('phone-green', 'svg', 'classique')"
                    :alt="phoneNumber"
                    class="vertical-align-sub icon"
                />
                {{ phoneNumber }}
              </a>
              <login-in-header-module />
            </div>
            <div class="burger-menu burger-menu-mob d-xl-none">
              <BurgerMenuModule :route="route" :top-articles="topArticles" />
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import BurgerMenuModule from "../../../components/modules/layouts/BurgerMenuModule";
import LoginInHeaderModule from "../../../components/modules/layouts/LogInHeaderModule";
import { mutations, store } from "../../../store/header";
import UtilMixin from "../../../mixins/UtilMixin";
import BannersModule from '../../../components/modules/banners/bannersModule.vue'


export default {
  name: "HeaderModule",
  components: {
    BannersModule,
    "sub-menu-buyer": () =>
      import("../../../components/modules/commons/subMenuBuyer.vue"),
    "sub-menu-seller": () =>
      import("../../../components/modules/commons/subMenuSeller.vue"),
    "sub-menu-board": () =>
      import("../../../components/modules/commons/subMenuBoard.vue"),
    "sub-menu-advice": () =>
      import("../../../components/modules/commons/subMenuAdvice.vue"),
    BurgerMenuModule,
    LoginInHeaderModule,
  },
  mixins: [UtilMixin],
  data() {
    return {
      subdomain: null,
      phoneNumber: null,
      title: null,
      phoneCompactPrefix: null,
      route: "",
      blog: process.env.BLOG,
      partner: null,
      activeSeller: false,
      activeBuyer: false,
      activeBoard: false,
      activeAdvice: false,
      topArticles: [],
      store
    };
  },
  beforeCreate(){
    const query = `
            query {
        getListsArticles(limit: 3) {
          getListsArticles {
            date
            link
            title
            image
            description
          }
        }
      }`
    fetch('https://api.carizy.com/api/graphql', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer njbmztuxmjczmjhkotqzzwrintblzmi5mju0n2izm2flmwi1ntmwmtfkyzhmytvizgm4ntvmzdjmzmi2njnhyq',
      },
      body: JSON.stringify({ query: query })
    })
      .then(response => response.json())
      .then(json => {
        this.topArticles = json.data.getListsArticles.getListsArticles
      })
  },
  mounted() {
    // Partnaire loading
    this.subdomain = this.getPartenaire().subdomain;
    this.partner = this.getPartenaire().subdomain;
    this.phoneNumber = this.getPartenaire().phoneNumber;
    this.phoneCompactPrefix = this.getPartenaire(
      this.partner,
    ).phoneCompactPrefix;
    this.title = this.getPartenaire().title;
    this.route = this.getPartenaire().route;
  },
  methods: {
    validateUrl(url) {
      return url + this.route;
    },
    toggle() {
      mutations.toggleNav();
    },
    closeSidebarPanel: mutations.clossNav,
  },
};
</script>

<style lang="scss">
@import "../../../assets/sass/modules/headerModule";
</style>
