import { render, staticRenderFns } from "./BurgerMenuModule.vue?vue&type=template&id=232ec48b"
import script from "./BurgerMenuModule.vue?vue&type=script&lang=js"
export * from "./BurgerMenuModule.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./BurgerMenuModule.vue?vue&type=style&index=0&id=232ec48b&prod&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports